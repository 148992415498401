'use client';

import { AbsoluteCenter, Box, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

export default function NotFound() {
  const t = useTranslations();

  return (
    <AbsoluteCenter>
      <Text
        textStyle='heading-large-4xl'
        mb='20px'
      >
        404
      </Text>
      <Box textAlign='center'>
        <Text
          textStyle='heading-large-lg'
          mb='20px'
        >
          {t('NotFound.pageNotFound')}
        </Text>
      </Box>
      <Box textAlign='center'>
        <Link href='/'>{t('NotFound.returnHome')}</Link>
      </Box>
    </AbsoluteCenter>
  );
}
